<template>
	<div class="scale-container" :style="'width:' + w + ';'">
		<div class="scale-wrapper" :style="'padding-bottom:' + scale * 100 + '%;'">
			<div class="scale-content">
				<div
					v-if="img && img.length > 10"
					class="scale-img"
					:style="
						'background-image:url(' + img + ';background-size:cover; background-position:center center;'
					"
				></div>
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['scale', 'w', 'img'],
};
</script>

<style scoped>
.scale-wrapper {
	position: relative;
	padding-top: 0px;
	height: 0;
}

.scale-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.scale-img {
	width: 100%;
	height: 100%;
}
</style>