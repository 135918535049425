<template>
	<div class="tpList">
		<div class="lis">
			<a v-if="msg.jump == 'url'" :href="msg.url" :title="msg.title" target="_blank">
				<ScaleDiv :scale="268 / 398" w="100%" :img="GS(msg.thumb)"> </ScaleDiv>
				<p>{{ msg.title }}</p>
			</a>
			<router-link
				:to="'/details/text/' + msg.id + '?type=' + types + '&pid=' + id + '&sx=' + sx + '&index=' + index"
				:title="msg.title">
				<!-- <router-link v-else :to="'/details/cont/'+msg.id"> -->
				<ScaleDiv :scale="268 / 398" w="100%" :img="GS(msg.thumb)"> </ScaleDiv>
				<p>{{ msg.title }}</p>
			</router-link>
		</div>
	</div>
</template>

<script>
import ScaleDiv from './ScaleDiv.vue';
export default {
	components: {
		ScaleDiv,
	},
	name: 'tptList',
	props: {
		msg: {
			type: Object,
			default() {
				return [];
			},
		},
		id: {
			type: String,
			default: '',
		},
		sx: {
			type: String,
			default: '',
		},
		index: {
			type: String,
			default: '',
		},
		types: {
			type: String,
			default: '',
		},
		tzfs: {
			type: String,
			default: 'cont',
		},
	},
	created() {
		// console.log(this.msg, 12)
	},
};
</script>

<style>
.fff {
	position: absolute;
}
</style>
